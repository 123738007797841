.search_item {
  display: flex;
  padding: 10px 0px;
  align-items: center;
  font-size: 0.9rem;
  transition: 0.2s ease all;
  border-radius: 5px;
  cursor: pointer;
}
.search_item:hover {
  background-color: #eeeeee;
}
.search_item.dark:hover {
  background-color: #c2c2c236;
}
.search_item img {
  margin-right: 20px;
  margin-left: 10px;
}
.price_item {
  display: flex;
  align-items: center;
  margin: 5px 10px 5px 0;
}
.sale_price {
  margin: 0 10px;
  color: var(--red_color);
  font-family: var(--neuzeit-light-font);
}
.discount_percentage {
  background-color: #dfc8e7;
  padding: 5px 7px;
  font-size: 0.6rem;
  font-family: var(--neuzeit-light-font);
  border-radius: 5px;
  letter-spacing: 0.5px;
}
.discount_percentage.dark {
  color: #000;
}
/** MOBILE BREAKPOINT */

@media only screen and (max-width: 1023px) {
  .search_item {
    position: relative;
  }
  .search_item img {
    margin-right: 10px;
    margin-left: 0px;
  }
  .price_item {
    flex-direction: column;
    align-items: flex-start;
  }
  .sale_price {
    margin: 0;
  }
  .discount_percentage {
    position: absolute;
    bottom: 20px;
    right: 10px;
  }
}
@media only screen and (max-width: 650px) {
  /* .search_item {
    padding: 10px 0px;
  } */
}
