.header {
  position: fixed;
  z-index: 100;
  width: 100vw;
  transition: 0.2s all ease;
}

.header_menu {
  background-color: #fff;
  padding: 13px 80px;
}
.header_menu.dark {
  background-color: #000;
  color: #fff;
}

.header .header_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main_menu {
  display: flex;
  justify-content: space-between;
}
.list_menu {
  margin: 0 1vw;
  font-family: var(--neue-light-font);
  font-size: 0.9rem;
}
.right_corner_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 15vw;
}

/* navigation menu  */
.dropdown_content {
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.2s ease all;
  position: absolute;
  width: 100%;
  left: 0;
}
.list_menu:hover .dropdown_content {
  transform: scaleY(1);
  width: 100%;
  position: absolute;
  left: 0;
}
