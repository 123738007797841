.search_icon {
  cursor: pointer;
  line-height: 0;
}
.search_icon.dark {
  filter: invert(1);
}
.canvas_search_position {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  background-color: #fff;
  width: 100vw;
  overflow: hidden;
  transition: 0.3s all ease;
  box-shadow: 0px 0px 2px #00000070;
  /* transform-origin: top; */
}
.canvas_search_position.dark {
  background-color: #000;
  color: #fff;
}
.canvas_search {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 40px 80px 100px 80px;
}
.close_icon_popup {
  position: absolute;
  right: 40px;
  top: 20px;
  font-size: 1.2rem;
  color: #a7a2a2;
  cursor: pointer;
}
.search {
  width: 60%;
}
.search_title {
  font-family: var(--neuzeit-reg-font);
  margin: 10px 0 50px 0;
  font-size: 1.2rem;
}
.search_menu_list div {
  margin: 5px 0;
  width: fit-content;
}
.input_search_section {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.search_input {
  width: 100%;
}

.search_input.dark input {
  border-bottom: 1px solid #fff;
  color: #fff;
}
.search_input.dark label {
  color: #fff;
}
.input_search_section input {
  width: 90%;
}
.input_search_section label {
  font-size: 0.8rem;
}

.input_search_section img {
  margin-left: -20px;
  margin-bottom: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  z-index: 2;
}

.trending_section {
  display: flex;
}
.trending {
  margin-right: 100px;
  font-size: 0.85rem;
}

.top_search {
  font-size: 0.85rem;
  display: flex;
  flex: 1 1 auto !important;
  flex-direction: column;
}
.top_search_products img {
  margin: 0 10px 0 0;
  cursor: pointer;
}
.trending .trending_title,
.top_search .top_search_title,
.search_result_title {
  color: #3a3a3a;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 0.8rem;
}
.trending.dark .trending_title.dark,
.top_search.dark .top_search_title.dark,
.search_result_title.dark {
  color: #fff;
}
.trending_category div {
  margin: 3px 0;
}
.top_search_products {
  width: 100%;
  display: flex;
}
.below_search {
  position: relative;
  margin-top: 20px;
}
.search_result {
  /* display: none; */
  position: absolute;
  top: 0;
  background-color: #fff;
  width: 90%;
  height: 200px;
}
.search_result.dark {
  background-color: #000;
  color: #fff;
}
.search_result_items {
  background-color: #fff;
  height: 170px;
  overflow-y: scroll;
}
.search_result_items.dark {
  background-color: #000;
  color: #fff;
}

.search_result_items::-webkit-scrollbar {
  width: 3px;
}
/** BREAKPOINT */
@media only screen and (min-width: 1600px) {
}

@media only screen and (max-width: 1420px) {
}
@media only screen and (min-width: 1024px) and (max-width: 1300px) {
  .top_search_products img {
    width: 60px;
    height: 60px;
  }
}
