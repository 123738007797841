

.countdown_notice{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 98vw;
  height: 2vh;
  margin: auto;
  font-family: var(--neuzeit-reg-font);
}

.count_time{
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
}

.ends{
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  color: white;
  margin-right: 10px;
  font-size: 12px;
}

.timer_comp{
  display: flex;
  color: white;
  text-align: center;
}

.discount{
  color: white;
  font-size: 12px;
  line-height: 1.3rem;
}

.timer{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0.3rem;
}

.clock{
  display: flex;
  color: white;
  text-align: center; 
  font-weight: 700;
}

.description{
  font-size: 11px;
}


@media only screen and (max-width: 415px){

.countdown_notice{
  height: 5vw;
}

  .count_time{
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    color: white;
  }

  .timer_comp{
    font-size: 12px;
  }

  .ends{
    position: absolute;
    right: 2px;
    font-size: 9px;
  }

  .clock{
    margin-top: 15px;
  }

  .separator{
    margin-top: 15px;
  }

  .description{
    font-size: 8px;
  }

  .discount{
    color: white;
    font-size: 9px;
    line-height: 1.6em;
  }

  .timer{
    margin: 0 0.2rem;
  }

}