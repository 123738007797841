.block_main_navigation {
  position: relative;
  width: 100%;
  height: 100px;
  /* height: 150px; for point notification*/
}
.block_main_navigation.dark {
  background-color: #000;
}

/** MOBILE BREAKPOINT */

@media only screen and (max-width: 1023px) {
  .block_main_navigation {
    height: 0px;
  }
}
@media only screen and (max-width: 650px) {
  .block_main_navigation {
    height: 0px;
  }
}
