.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 2px 2px #0000001c;
  padding: 30px;
}
.buttons {
  display: flex;
  margin-top: 20px;
}
.confirm_button {
  margin-right: 10px;
}
/** MOBILE BREAKPOINT */

@media only screen and (max-width: 1023px) {
}
@media only screen and (max-width: 650px) {
  .modal {
    width: 90%;
  }
}
