.my_account {
  font-weight: 100;
  text-transform: uppercase;
  font-family: var(--neuzeit-light-font);
  cursor: pointer;
}
.canvas_account_position {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  background-color: #fff;
  width: 100vw;
  overflow: hidden;
  transition: 0.3s all ease;
  box-shadow: 0px 0px 2px #00000070;
  /* transform-origin: top; */
}
.canvas_account_position.dark {
  background-color: #000;
  color: #fff;
}
.canvas_account {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 40px 80px 100px 80px;
}
.close_icon_popup {
  position: absolute;
  right: 40px;
  top: 20px;
  font-size: 1.2rem;
  color: #a7a2a2;
  cursor: pointer;
}
.account {
  position: relative;
  display: flex;
  width: 60%;
  align-items: flex-end;
  justify-content: space-between;
}
.account_title {
  font-family: var(--neuzeit-reg-font);
  margin: 10px 0 50px 0;
  font-size: 1.2rem;
}
.account_menu_list div {
  margin: 5px 0;
  width: fit-content;
}
