.wrapper {
  position: relative;
  padding: 15px 0px;
  z-index: 100;
  background-color: #000;
}
.point_notice {
  position: relative;
  padding: 15px 0px;
  z-index: 100;
  background-color: #6688cc;
}
.store_content {
  /* font-size: 12px; */
  font-size: 0.8vw;
  color: #fff;
  text-align: center;
  margin: 0 50px;
}
@media only screen and (max-width: 1023px) {
  .store_content {
    font-size: 1vw;
  }
}
@media only screen and (max-width: 650px) {
  .store_content {
    font-size: 2vw;
  }
}
