.logo {
  width: 150px;
  height: 35px;
}
/** BREAKPOINT */
@media only screen and (min-width: 1600px) {
}

@media only screen and (max-width: 1420px) {
}
@media only screen and (min-width: 1024px) and (max-width: 1300px) {
}

/** MOBILE BREAKPOINT */

@media only screen and (max-width: 1023px) {
}
@media only screen and (max-width: 650px) {
  .logo {
    width: 150px;
    height: 35px;
  }
}
