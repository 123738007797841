.cart_length {
  position: absolute;
  top: -10px;
  right: -2px;
  font-size: 0.8rem;
}
.cart_image.dark {
  filter: invert(100);
}
/** MOBILE BREAKPOINT */

@media only screen and (max-width: 1023px) {
}
@media only screen and (max-width: 650px) {
  .cart_image {
    width: 20px;
    height: 20px;
  }
  .cart_length {
    right: -5px;
  }
}
