.has_sticky {
  width: 100%;
  height: 80px;
  /* height: 100px; for point notification */
  position: relative;
}
.header_section {
  position: fixed;
  top: 0;
  width: 100%;
  height: 80px;
  display: flex;
  background-color: #fff;
  justify-content: space-between;
  padding: 25px 20px;
  z-index: 100;
}
.header_section.dark {
  color: #fff;
  background-color: #000;
}
.header_section .hamburger_menu {
  font-size: 1.8em;
}

/** MOBILE BREAKPOINT */

@media only screen and (max-width: 1023px) {
}
@media only screen and (max-width: 650px) {
  .header_section .hamburger_menu {
    font-size: 1.4em;
  }
}
